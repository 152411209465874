// products
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SET_SELECTED_MODULES = 'SET_SELECTED_MODULES';

// user
export const SIGNUP = 'SIGNUP';
export const SIGNIN = 'SIGNIN';
export const FETCH_USER = 'FETCH_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SIGNOUT = 'SIGNOUT';

export const SEND_INVITE = 'SEND_INVITE';
export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_USERS_ROLES = 'GET_USERS_ROLES';
export const ASSIGN_USER_ROLE = 'ASSIGN_USER_ROLE';
export const GET_COMPANY_PERMISSIONS = 'GET_COMPANY_PERMISSIONS';
export const GET_COMPANY_USER_PERMISSIONS = 'GET_COMPANY_USER_PERMISSIONS';
export const ASSIGN_TEMP_PERMISSIONS = 'ASSIGN_TEMP_PERMISSIONS';
export const REMOVE_TEMP_PERMISSIONS = 'REMOVE_TEMP_PERMISSIONS';

// response
export const NOTIFY = 'NOTIFY';
export const LOADING = 'LOADING';

// Plan
export const SUBSCRIBE_TO_PLAN = 'SUBSCRIBE_TO_PLAN';
export const FETCH_PLANS = 'FETCH_PLANS';
